<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Storage Locations')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space/>

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Print barcodes')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="printBarcodes"
        />

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

        <!-- <q-btn
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          :disable="!hasBaseAccess"
          @click="create"
        /> -->
      </q-card-section>

      <filter-collapse
          :is-open="isOpen"
          :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          query: {
            warehouse: [
              { type: 'eq', field: 'type', value: 'fulfillment' }
            ]
          },
          values: {
            states: statuses,
            'state==w': warehouseStates,
            types: types
          },
          style: {
            noGroups: true
          }
        }"
          @submit="handleFiltersSubmit"
          @close="openCloseFilters"
      />

      <q-card-section class="row q-pa-none">
        <status-filter
            class="hide-on-mobile"
            :title="'Types'"
            :outside-selected-status="selectedTypes"
            :statuses="typeMatrix"
            @on-change="onTypeChange"
        />

        <div class="col">
          <q-table
              style="height: calc(100vh - 130px);"
              class="sticky-header-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="storagePlaces"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="storagePlacesLoading"
              :filter="filter"
              virtual-scroll
              binary-state-sort
              flat
              @request="onRequest"
          >
            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>

            <template v-slot:body="props">
              <storage-place-row
                  :data="props"
                  @dblclick="onRowDblClick"
              />
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>

    <confirm-modal ref="confirmModal"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import Search from '../../components/search/Search.vue'
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import StatusFilter from '../../components/filters/StatusFilter.vue'
import StoragePlaceRow from '../../components/tables/StoragePlaceRow.vue'
import ConfirmModal from '../../components/confirm-modal/ConfirmModal.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Mixins
import TableMixin from './../../components/global/TableMixin'

// Services
import { InstructionsService } from '../../services/instructions.service'

// Config
import placeMatrix from './../../config/PlaceMatrix'

export default {
  name: 'StoragePlaces',
  components: {
    Search,
    FilterCollapse,
    StatusFilter,
    StoragePlaceRow,
    ConfirmModal
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      stateColors: {
        normal: 'success',
        blocked: 'dark'
      },
      typeMatrix: placeMatrix,
      statuses: [
        { id: 'normal', title: 'Normal' },
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' },
        { id: 'blocked', title: 'Blocked' }
      ],
      warehouseStates: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' },
      ],
      types: [
        { id: 'room', title: 'Room' },
        { id: 'section', title: 'Section' },
        { id: 'static', title: 'Static' },
        { id: 'dynamic', title: 'Dynamic' },
        { id: 'employee', title: 'Employee' },
        { id: 'sorting', title: 'Sorting' },
        { id: 'distribution', title: 'Distribution' },
        { id: 'distribution_rejected', title: 'Distribution_rejected' },
        { id: 'assembly', title: 'Assembly' },
        { id: 'universal', title: 'Universal' },
        { id: 'pallet', title: 'Pallet' },
        { id: 'defected', title: 'Defected' }
      ],
      instructionsService: null,
      activatedFields: [
        'state',
        'placeAlias',
        'id',
        'extId',
        'type',
        'warehouse',
        'created.from',
        'created.to',
        'parent',
        'barcode',
        'locationAddress',
        'state==w'
      ],
    }
  },

  computed: {
    ...mapGetters([
      'storagePlaces',
      'storagePlacesLoading',
      'appOptions',
      'totalStoragePlacesNumber',
      'currentUser',
      'hasBaseAccess'
    ]),
    selectedTypes () {
      const types = (this.serverParams.filter || []).find(filter => {
        return filter.field === 'type'
      })

      if (types && types.values) {
        return types.values
      }

      return types
          ? [types.value]
          : []
    },
    columns () {
      return [
        {
          label: this.$t('Location address and type'),
          name: 'location',
          align: 'left'
        },
        {
          label: this.$t('Code') + ' (S/P/\'ID\'), ' + this.$t('Parent code'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Warehouse'),
          name: 'warehouse',
          align: 'left'
        },
        {
          label: this.$t('Pin'),
          name: 'pin',
          align: 'center'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Label'),
          name: 'label',
          align: 'center'
        }
      ]
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ' + this.totalStoragePlacesNumber)
          : this.$t('Filter')
    }
  },
  mounted () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))
    if (this.storagePlaces.length === 0) {
      this.loadDefaultItems()
    }

  },
  methods: {
    ...mapActions([
      'loadStoragePlaces'
    ]),
    ...mapMutations([
      'setStoragePlace'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    onTypeChange (data) {
      let filter = this.serverParams.filter || []
      filter = filter.filter(x => x.field !== 'type')

      if (data.length > 0) {
        filter.push({ type: 'in', field: 'type', values: data })
      }

      return this.onRequest({ pagination: { per_page: 25, page: 1, filter } })
    },
    printBarcodes () {
      this.$service.storagePlace.printAll(this.serverParams)
          .then(({ text }) => {
            this.$service.printer.print(text, 'LABEL', undefined, true)
          })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({})
          .then(({ totalItems }) => {
            if (totalItems <= 0) {
              this.create()
            }
          })
    },
    convertFilters (filter) {
      const hasLocationAddress = filter.find(x => x.field === 'locationAddress')

      return filter.reduce((acc, x) => {
        const adapter = {
          barcode: () => {
            const value = x.value
                .split('*')[0]
                .split('S/P/')
                .filter(x => !!x)[0]

            acc.push({ ...x, field: 'id', value })
          },
          locationAddress: () => {
            const fields = ['room', 'row', 'rack', 'shelf']

            const filters = x.value.replaceAll(' ', '').split('-').reduce((acc, x, i) => {
              if (!fields[i] || x === '' || x === undefined) {
                return acc
              }

              acc.push({ type: 'eq', field: fields[i], value: x })
              return acc
            }, [])

            acc = [...filters, ...acc]
          },
          room: () => {
            if (hasLocationAddress) {
              return
            }

            acc.push(x)
          },
          row: () => {
            if (hasLocationAddress) {
              return
            }

            acc.push(x)
          },
          rack: () => {
            if (hasLocationAddress) {
              return
            }

            acc.push(x)
          },
          shelf: () => {
            if (hasLocationAddress) {
              return
            }

            acc.push(x)
          },
          default: () => {
            acc.push(x)
          }
        }

        if (adapter[x.field]) {
          adapter[x.field]()
        } else {
          adapter.default()
        }

        return acc
      }, [])
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}

      if (this.pagination.filter) {
        this.pagination.filter = this.convertFilters(this.pagination.filter)
      }

      const query = buildQuery(this.pagination)

      if (query.filter) {
        let index = query.filter.findIndex(x => x.alias === 'w')

        if (index > -1) {
          query.filter = query.filter.filter(x => x.type !== 'innerjoin' && x.field !== 'warehouse')
          index = query.filter.findIndex(x => x.alias === 'w')

          query.filter = [
            ...query.filter.slice(0, index),
            {
              type: 'innerjoin',
              field: 'warehouse',
              parentAlias: 'p',
              alias: 'w'
            },
            query.filter[index],
            ...query.filter.slice(index + 1),
          ]
        }
      }

      this.updateParams(query)
      return this.loadStoragePlaces(this.serverParams)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            return { items, totalPages, page, totalItems }
          })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    create () {
      this.$router.push('/storage/locations/entity')
    },
    onRowDblClick (row) {
      this.setStoragePlace(row)
      this.$router.push('/storage/locations/entity/' + row.id)
    }
  }
}
</script>
