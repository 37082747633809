<template>
  <q-tr
    :props="data"
    class="clickable"
    @dblclick="onRowDblClick"
  >
    <q-td
      key="location"
      :props="data"
      auto-width
    >
      <new-picking-place
        :data="data.row"
        :type="$t(data.row.type)"
        badge
      />
    </q-td>

    <q-td
      key="id"
      :props="data"
      class="text-subtitle1"
      auto-width
    >
      <strong>{{ data.row.code }}</strong>

      <div v-if="data.row._embedded.parent">
        {{ data.row._embedded.parent.code }}
      </div>

      <strong>{{ data.row.extId }}</strong>
    </q-td>

    <q-td
      key="name"
      :props="data"
      auto-width
    >
      <q-badge
        :color="stateColors[data.row.state]"
        class="q-mr-sm"
        rounded
      />

      {{ data.row.placeAlias || '-' }}
    </q-td>

    <q-td
      key="warehouse"
      :props="data"
      auto-width
    >
      <span v-if="data.row._embedded.warehouse">
        <q-badge
          :color="stateColors[data.row._embedded.warehouse.state]"
          class="q-mr-sm"
          rounded
        />

        {{ `${data.row._embedded.warehouse.name} (${data.row._embedded.warehouse.id})` }}
      </span>
    </q-td>

    <q-td
      key="pin"
      :props="data"
      auto-width
    >
      <q-btn
        size="sm"
        :color="note.color"
        :icon="note.icon"
        @click="handleNote"
        no-caps
      />
    </q-td>

    <q-td
      key="created"
      :props="data"
      auto-width
    >
      {{ $moment(data.row.created.date).format(appOptions.formats.date) }}
    </q-td>

    <q-td
      key="label"
      :props="data"
      auto-width
    >
      <q-btn
        color="dark"
        text-color="white"
        :label="$t('Print')"
        size="sm"
        no-caps
        unelevated
        @click="printBarcode"
      />
    </q-td>
  </q-tr>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import NewPickingPlace from '../picking/new-picking-place.vue'

export default {
  name: 'StoragePlaceRow',
  emits: ['dblclick'],
  components: {
    NewPickingPlace
  },
  props: {
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      stateColors: {
        active: 'active',
        inactive: 'inactive'
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'routeShortcuts'
    ]),
    route () {
      return `/storage/locations/entity/${this.data.row.id}`
    },
    note () {
      if (this.routeShortcuts.find(({ route }) => route === this.route)) {
        return {
          color: 'positive',
          label: 'Unpin',
          icon: 'push_pin'
        }
      }

      return {
        color: 'dark',
        label: 'Pin',
        icon: 'push_pin'
      }
    }
  },

  methods: {
    ...mapMutations([
      'removeRouteToShortcuts',
      'addRouteToShortcuts'
    ]),
    printBarcode () {
      const query = {
        filter: [
          { type: 'eq', field: 'id', value: this.data.row.id }
        ]
      }

      this.$service.storagePlace.printAll(query)
        .then(({ text }) => {
          this.$service.printer.print(text, 'LABEL', undefined, true)
        })
    },
    onRowDblClick () {
      this.$emit('dblclick', this.data.row)
    },
    handleNote () {
      const action = this.routeShortcuts.find(({ route }) => route === this.route)
        ? 'removeRouteToShortcuts'
        : 'addRouteToShortcuts'

      const data = {
        route: this.route,
        name: `S/P/${this.data.row.id}*`,
        description: this.data.row.placeAlias || this.$t('No name'),
        caption: this.data.row.type
      }

      this[action](data)
    }
  }
}
</script>
